<div
  (click)="openNavModal()"
  class="flex h-12 cursor-pointer items-center justify-between rounded border border-primary-400 bg-white px-4 py-2"
>
  <div class="flex items-center gap-2">
    <app-hes-icon
      class="flex text-2xl"
      [hesIcon]="{ src: iconPath() }"
    ></app-hes-icon>
    <span class="text-sm font-semibold text-[#37474F]">
      {{ title() }}
    </span>
  </div>
  <fa-icon [icon]="faArrowRight" class="text-2xl text-[#37474F]"></fa-icon>
</div>
