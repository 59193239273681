<nav aria-label="breadcrumb" class="xng-breadcrumb-root" [ngClass]="class">
  <ol class="xng-breadcrumb-list">
    <ng-container
      *ngFor="
        let breadcrumb of breadcrumbs$ | async;
        last as isLast;
        first as isFirst;
        index as index;
        count as count
      "
    >
      <li class="xng-breadcrumb-item">
        <a
          *ngIf="!isLast"
          class="xng-breadcrumb-link font-normal text-[#9EA2B3] hover:text-primary"
          [ngClass]="{ 'xng-breadcrumb-link-disabled': breadcrumb.disable }"
          [attr.aria-disabled]="breadcrumb.disable"
          [attr.tabIndex]="breadcrumb.disable ? -1 : 0"
          rel="noopener noreferrer"
          [routerLink]="
            breadcrumb.routeInterceptor
              ? breadcrumb.routeInterceptor(breadcrumb.routeLink!, breadcrumb)
              : breadcrumb.routeLink
          "
          [queryParams]="
            preserveQueryParams ? breadcrumb.queryParams : undefined
          "
          [fragment]="preserveFragment ? breadcrumb.fragment : undefined"
          [target]="anchorTarget ? anchorTarget : '_self'"
        >
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                $implicit: breadcrumb.label,
                info: breadcrumb.info,
                last: isLast,
                first: isFirst,
                index: index,
                count: count
              }
            "
          ></ng-container>
          <ng-container *ngIf="!itemTemplate">{{
            breadcrumb.label
          }}</ng-container>
        </a>

        <label *ngIf="isLast" class="xng-breadcrumb-trail text-linkLg">
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                $implicit: breadcrumb.label,
                info: breadcrumb.info,
                last: isLast,
                first: isFirst,
                index: index,
                count: count
              }
            "
          ></ng-container>
          <ng-container *ngIf="!itemTemplate">{{
            breadcrumb.label
          }}</ng-container>
        </label>
      </li>

      <li
        *ngIf="!isLast"
        class="xng-breadcrumb-separator text-[#9EA2B3]"
        aria-hidden="true"
      >
        <ng-container *ngTemplateOutlet="separatorTemplate!"></ng-container>
        <ng-container *ngIf="!separatorTemplate">{{ separator }}</ng-container>
      </li>
    </ng-container>
  </ol>
</nav>
