<ion-app>
  <tui-root>
    <app-splash-screen-web
      [ngClass]="{
        'z-50 h-full': showSplashScreen(),
        hidden: !showSplashScreen()
      }"
    ></app-splash-screen-web>
    <ion-router-outlet></ion-router-outlet>
  </tui-root>
</ion-app>
