import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { isMobile } from '@shared/utils/platform';

export const MobileMenuGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
) => {
  const showMenuPage = isMobile();

  // Allow the user to proceed if all the required roles are present.
  return showMenuPage ? true : inject(Router).navigate(['/home']);
};
