<div class="flex flex-col items-stretch justify-center gap-8 py-8">
  <div class="flex flex-col items-stretch justify-start gap-1 px-8">
    @for (item of schoolStructure(); track item.id) {
      <app-school-structure-nav-item
        [item]="item"
        [firstLevel]="true"
      ></app-school-structure-nav-item>
    }
  </div>
  <ion-button
    (click)="submit()"
    class="w-full px-4"
    hesBtn
    hesSize="lg"
    expand="block"
    hesColor="primary"
    >Submit</ion-button
  >
</div>
