import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { TranslocoDirective, TranslocoModule } from '@jsverse/transloco';
import { Gender, UserType } from '@shared/enums';
import {
  IonPopover,
  IonList,
  IonItem,
  IonIcon,
  IonButton,
} from '@ionic/angular/standalone';
import { randomId } from '@shared/utils/randomId';
import { NgIconComponent } from '@ng-icons/core';
import { HesInitialsPipe } from '@shared/pipes/hes-name-initials.pipe';
import { HessaBtnDirective } from '@ui-kit/hes-button/hes-button.directive';
import { HesIconComponent } from '@shared/components/hes-icon/hes-icon.component';
import {
  faChevronLeft,
  faChevronRight,
  faCircleArrowDown,
} from '@fortawesome/pro-regular-svg-icons';
import { FaIconComponentsProps } from '@shared/types';
import { isMobile, isRtl } from '@utils/platform';
import { SchoolStructureApiService } from '@ui-kit/hes-school-structure-control/data-access/school-structure-api.service';
import { SchoolStructureScopeService } from '@core/school-structure-scope.service';

@Component({
  selector: 'app-header-user-info',
  templateUrl: './header-user-info.component.html',
  standalone: true,
  imports: [
    IonItem,
    IonList,
    IonPopover,
    CommonModule,
    TranslocoDirective,
    NgIconComponent,
    HesInitialsPipe,
    HessaBtnDirective,
    IonButton,
    RouterLink,
    HesIconComponent,
  ],
})
export class HeaderUserInfoComponent {
  protected auth = inject(AuthService);
  protected schoolStructureScopeService = inject(SchoolStructureScopeService);
  readonly isSchoolStructureEmpty =
    this.schoolStructureScopeService.isSchoolStructureEmpty();
  isMobile = isMobile();
  randomId: string = randomId();
  gender = Gender;
  readonly arrowRightIcon: FaIconComponentsProps = {
    icon: isRtl() ? faChevronLeft : faChevronRight,
    size: 'lg',
  };
  user = computed(() => {
    return this.auth.user();
  });

  userProfileUrl = signal(this.getUserProfileRoute());

  actions = computed(() => {
    const isSchoolStructureEmpty =
      this.schoolStructureScopeService.isSchoolStructureEmpty();
    const actionsArray = [
      {
        texti18nKey: 'global.profile.title',
        icon: 'saxUserOutline',
        routerLink: this.userProfileUrl(),
      },
      {
        texti18nKey: 'global.help_center.title',
        icon: 'saxInfoCircleOutline',
        routerLink: 'help-center',
      },
      ...(isSchoolStructureEmpty
        ? []
        : [
            {
              texti18nKey: 'global.settings.title',
              icon: 'saxSetting2Outline',
              routerLink: 'settings',
            },
          ]),
      {
        texti18nKey: 'global.logout.btn',
        icon: 'saxLogout1Outline',
        onClick: () => {
          this.auth.logout();
        },
      },
    ];
    if (this.auth.isLoggedInAsOtherUser() && this.isMobile) {
      actionsArray.unshift({
        texti18nKey: 'global.back_to_admin_account.btn',
        icon: 'saxArrowLeft2Outline',
        onClick: () => {
          this.backToAdmin();
        },
      });
    }
    return actionsArray;
  });

  userTypeTranslocoKey = 'global.' + this.user()?.type.toLowerCase() + '.txt';

  getUserProfileRoute() {
    switch (this.user()!.type) {
      case UserType.STUDENT:
        return `user-management/students/${this.user()?.userTypeId}`;
      case UserType.GUARDIAN:
        return `user-management/guardians/${this.user()?.userTypeId}`;
      case UserType.PERSONNEL:
        return `user-management/personnels/${this.user()?.userTypeId}`;
    }
  }

  backToAdmin() {
    this.auth.restoreOriginalProfile();
  }
}
