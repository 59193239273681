<div *transloco="let t" class="splash-screen">
  <div style="position: relative">
    <!-- update source for mobile -->
    <img
      src="/assets/logos/hessa-logo-splash.svg"
      srcset="
        /assets/logos/hessa-logo-splash.svg 650w,
        /assets/logos/hessa-logo-splash.svg 768w
      "
      alt="Hessa logo"
      fetchPriority="'high'"
    />
    <div class="beta-release">
      <span>beta</span>
      <span>release</span>
    </div>
  </div>
  <div class="loading flex flex-col items-start justify-start gap-3">
    <div>{{ t("global.loading.txt") }} ...</div>
    <div class="progress-bar">
      <div class="progress-bar-value"></div>
    </div>
  </div>
</div>
