import { Component, OnInit } from '@angular/core';
import { IonProgressBar } from '@ionic/angular/standalone';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-splash-screen-web',
  templateUrl: './splash-screen-web.component.html',
  styleUrls: ['./splash-screen-web.component.scss'],
  standalone: true,
  imports: [IonProgressBar, TranslocoDirective],
})
export class SplashScreenWebComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
