import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { ChatService } from '@pages/chat/chat.service';

export const chatGuard = (): CanActivateFn => {
  return (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
    const chatService = inject(ChatService);
    if (!chatService.isChatLoggedIn()) {
      chatService.displayChatAuthMessage();
      return false;
    }
    return true;
  };
};
