import { inject } from '@angular/core';
import { Router, type ResolveFn } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { AcademicYearsScopeService } from '@core/academic-years-scope.service';
import { SchoolStructureScopeService } from '@core/school-structure-scope.service';
import { StudentSelectionScopeService } from '@core/student-selection-scope.service';
import { TranslocoService } from '@jsverse/transloco';
import { sideMenuSchoolStructureItem } from '@layout/layout.component';
import { GuardianService } from '@pages/user-management/guardians/guardians.service';
import { StudentsService } from '@pages/user-management/students/students.service';
import { AcademicYearItem } from '@shared/interfaces/academic-year-scope.interface';
import { HesToasterService } from '@shared/services/hes-toaster.service';
import { EMPTY, map, of } from 'rxjs';

export const StudentsScopeResolver: ResolveFn<any> = (_route, _state) => {
  const studentSelectionScopeService = inject(StudentSelectionScopeService);
  const schoolStructureScopeService = inject(SchoolStructureScopeService);
  const authService = inject(AuthService);
  const guardianService = inject(GuardianService);
  const studentService = inject(StudentsService);
  const academicYearScopeService = inject(AcademicYearsScopeService);
  const showNotLinkedToSchoolToast = createNotLinkedToSchoolToast();
  const user = authService.user();
  if (user?.type === 'GUARDIAN') {
    return guardianService.getGuardian(user?.userTypeId).pipe(
      map((g) => {
        const isAssociatedWithAnySchool = g?.schools.some((s) => s.id);
        if (!isAssociatedWithAnySchool) {
          showNotLinkedToSchoolToast();
          authService.logout(false);
          return EMPTY;
        }
        if (g?.students?.length) {
          studentSelectionScopeService.updateStudentSelectionScopeFromGuardianStudents(
            g.students,
          );
          studentSelectionScopeService.updateSelectedStudent(g.students[0].id);

          const firstStudentWithSchoolYear = g.students.find((s) => {
            const { schoolStructure } = s;
            const { school, academicYear } = schoolStructure?.[0] || {};
            return school?.id && academicYear?.id;
          })?.schoolStructure?.[0];

          if (firstStudentWithSchoolYear) {
            schoolStructureScopeService.updateSelectedStructure({
              id: firstStudentWithSchoolYear.school.id,
              type: 'school',
            } as sideMenuSchoolStructureItem);

            academicYearScopeService.updateSelectedAcademicYear({
              id: firstStudentWithSchoolYear.academicYear.id,
              name: firstStudentWithSchoolYear.academicYear.name,
            } as AcademicYearItem);
          }
        }
        return g.students;
      }),
    );
  } else if (user?.type === 'STUDENT') {
    return studentService.getStudent(user?.userTypeId).pipe(
      map((res) => {
        if (!res?.levels?.id) {
          showNotLinkedToSchoolToast();
          authService.logout(false);
          return EMPTY;
        }
        const mappedStudent = {
          ...res,
          school: {
            ...res.school,
            class: {
              id: res?.classes?.[0].id,
              name: res?.classes?.[0].name,
            },
            level: {
              id: res.levels.id,
              name: res.levels.name,
            },
          },
        };
        studentSelectionScopeService.updateStudentSelectionScope([
          mappedStudent,
        ]);
        studentSelectionScopeService.updateSelectedStudent(res.id);
        return res;
      }),
    );
  } else {
    return of([]);
  }
};

function createNotLinkedToSchoolToast() {
  const toaster = inject(HesToasterService);
  const translation = inject(TranslocoService);
  return function show() {
    toaster.showGlobalWrongMessage(
      translation.translate('login.not_linked_to_school.txt'),
    );
  };
}
