import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SchoolStructureScopeService } from '@core/school-structure-scope.service';
import { CourseManagementService } from '@pages/course-management/data-access/course-management.service';
import { findAllSchools } from '@shared/utils/school-structure';
import { filter, map, of, skip, switchMap, tap } from 'rxjs';

export const redirectIfSchoolStructureEmptyGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const schoolStructureScopeService = inject(SchoolStructureScopeService);
  const router = inject(Router);
  const courseService = inject(CourseManagementService);

  return schoolStructureScopeService
    .populateDefaultScope()

    .pipe(
      tap(() => {
        const schools = findAllSchools(
          schoolStructureScopeService.userScopedSchoolStructureTillSchool(),
        );
        if (schools.length === 1) {
          schoolStructureScopeService.updateSelectedStructure(schools[0]);
        }
      }),
      switchMap((resp) =>
        resp.length ? courseService.loadSubjectAndTeacherData() : of(null),
      ),
      map(() => {
        const isEmptySchoolStructure =
          schoolStructureScopeService.isSchoolStructureEmpty();
        if (isEmptySchoolStructure) {
          if (state.url !== '/help-center') {
            return router.createUrlTree(['/help-center']);
          }
        }
        return true;
      }),
    );
};
